import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import ProductPreview from '../components/molecules/ProductPreview';
import SEO from '../components/molecules/SEO';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import ProductListTableHeader from '../components/molecules/ProductListTableHeader';
import VendorLogo from '../components/atoms/VendorLogo';

const Holder = styled.div`
  margin: 6rem 0 10rem 0;
`;

const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
  h1 {
    margin: 0;
  }
`;


class Category extends Component {
  render() {

    const { contentfulVendor } = this.props.data;
    const { title, products, description, slug, training } = contentfulVendor;
    const { vendorHeroImage } = this.props.data.contentfulGlobalElements;

    return (
      <>
        <SEO title={title} description={description}/>
        <PreHead text={`${title} Products`} fluidImage={vendorHeroImage.fluid}/>
        <Container>
          <Holder>
            <TitleHolder>
              <VendorLogo vendor={contentfulVendor} showTitle={false}/>
              {training && <small><Link to={`/vendor-training/${slug}`}>View {title} training courses &rarr;</Link></small>}
            </TitleHolder>
            <ProductListTableHeader/>
            {products && products.map( node => (
              <ProductPreview key={node.id} product={node} vendor={contentfulVendor}/>
            ) )}
          </Holder>
        </Container>
      </>
    )
  }
}

export default Category;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulVendor(id: { eq: $id }) {
            title
            description
            slug
            logo {
                fluid(maxWidth: 1000) {
                    sizes
                    src
                    srcSet
                    aspectRatio
                }
            }
            training {
                title
            }
            products {
                id
                title
                slug
                excerpt {
                    excerpt
                }
            }
        }
        contentfulGlobalElements(title: {eq: "Global elements"}) {
            vendorHeroImage {
                fluid(maxWidth: 3000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
        }
    }
`;
